import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config'

import currencyFilter from '@/filters/currency.filter'
import dateFilter from '@/filters/date.filter'
import localizeFilter from '@/filters/localize.filter'
import numberFilter from '@/filters/number.filter'

import 'tailwindcss/tailwind.css'

function checkDomain() {
  if (process.env.NODE_ENV === 'production') {
    if (top.document.location.hostname !== document.location.hostname) {
      document.location.replace(process.env.VUE_APP_APP_URL)
    }
  }
}

checkDomain()

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true
}

const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENTID
})

getAnalytics(firebaseApp)

const app = createApp(App)
  .use(router)
  .use(store)

app.config.globalProperties.$filters = {
  currencyFilter, dateFilter, localizeFilter, numberFilter
}

app.mount('#app')

logEvent(getAnalytics(), 'appCreatedCOM')

const remoteConfig = getRemoteConfig(firebaseApp)

if (process.env.NODE_ENV !== 'production') {
  remoteConfig.settings.minimumFetchIntervalMillis = 10000
}

fetchAndActivate(remoteConfig)
  .then(() => {
    const country = getValue(remoteConfig, 'country')
    if (country.asString()) {
      store.commit('setCountryRemoteConfig', country.asString())
    }
  }).catch(e => {
    console.log(e)
  })
