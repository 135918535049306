<template>
  <div class="bottom-0 w-full">
    <div class="px-5 pt-2 pb-3 flex flex-wrap md:justify-between bg-gray-100 opacity-75 text-sm rounded-t-md">
      <div class="flex flex-wrap">
        <router-link
          class="hover:opacity-70 mr-4"
          :to="{ name: 'Legal' }"
          @click="openLegalClicked()"
        >
          {{ $filters.localizeFilter('LegalInformation') }}
        </router-link>
      </div>

      <div class="flex flex-row mb-2 mt-2 md:mt-1 mx-0.5 place-content-end w-full md:w-auto">
        <span
          v-for="(socialLink, idx) of socialLinks"
          :key="socialLink.title"
          :class="{'mr-1': socialLinks.length !== (idx + 1)}"
        >
          <a
            v-if="!socialLink.hide"
            :href="socialLink.url"
            target="_blank"
            :title="socialLink.title"
            @click="socialLinkClicked(socialLink.title)"
          >
            <img
              :src="getImgUrl(socialLink.logo)"
              class="h-4 opacity-50 hover:opacity-80 transition ease-in-out transform duration-300"
            >
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterNav',
  computed: {
    countryRemoteConfig () {
      return this.$store.getters.countryRemoteConfig
    },
    hideFacebook() {
      if (this.countryRemoteConfig || ['RUS'].includes(this.countryRemoteConfig)) {
        return true
      }
      return false
    },
    hideInstagram() {
      if (this.countryRemoteConfig || ['RUS'].includes(this.countryRemoteConfig)) {
        return true
      }
      return false
    }
  },
  watch: {
    countryRemoteConfig() {
      this.setHide()
    }
  },
  data: () => ({
    socialLinks: [
      { logo: 'fb.svg', url: 'https://www.facebook.com/BlueMoneyBoxApp', title: 'facebook' },
      { logo: 'vk.svg', url: 'https://vk.com/bluemoneybox', title: 'vk' },
      { logo: 'telegram.svg', url: 'https://t.me/bluemoneybox', title: 'telegram' },
      { logo: 'twitter.svg', url: 'https://twitter.com/bluemoneybox', title: 'twitter' },
      { logo: 'youtube.svg', url: 'https://www.youtube.com/channel/UCNL62wXGgfwk-y2XfkpInhA', title: 'youtube' },
      { logo: 'instagram.svg', url: 'https://instagram.com/bluemoneybox', title: 'instagram' },
      { logo: 'tiktok.svg', url: 'https://www.tiktok.com/@bluemoneybox', title: 'tiktok' },
      { logo: 'snapchat.svg', url: 'https://www.snapchat.com/add/bluemoneyboxapp', title: 'snapchat' }
    ]
  }),
  created () {
    this.setHide()
  },
  methods: {
    getImgUrl(filename) {
      return require('../../public/img/social/' + filename)
    },
    setHide() {
      if (this.hideFacebook) {
        const index = this.socialLinks.findIndex(element => {
          return element.title === 'facebook'
        })

        if (index >= 0) {
          this.socialLinks[index].hide = true
        } else {
          this.socialLinks[index].hide = false
        }
      }

      if (this.hideInstagram) {
        const index = this.socialLinks.findIndex(element => {
          return element.title === 'instagram'
        })

        if (index >= 0) {
          this.socialLinks[index].hide = true
        } else {
          this.socialLinks[index].hide = false
        }
      }

      if(!navigator.languages.includes('ru-RU') && !navigator.languages.includes('ru')) {
        const index = this.socialLinks.findIndex(element => {
          return element.title === 'vk'
        })

        if (index >= 0) {
          this.socialLinks[index].hide = true
        } else {
          this.socialLinks[index].hide = false
        }
      }
    },
    openLegalClicked () {
      this.$store.dispatch('logEvent', { eventName: 'openLegalClickedCOM' })
    },
    socialLinkClicked (title) {
      this.$store.dispatch('logEvent', { eventName: 'socialLinkClickedCOM', eventParams: { title: title } })
    }
  }
}
</script>
